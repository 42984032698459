import demo from '../img/demo.png'

const Demo = () => {
    
    return (
        <div className="demo">
          <img className="demo__img" src={demo} alt="demo" />
        </div>
        
    );
  };
  
export default Demo;